/* Component Dependencies */
var viewAllOfferTemplate = require('templates/viewAllOffer.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'viewAllOffer',
  template: {
    'viewAllOffer': viewAllOfferTemplate
  }
});
